<!-- 招聘动态 -->
<template>
  <div class="container mt-80-mb-140">
    <div class="row">
      <div class="col-12 col-xxl-12 col-xl-12 col-md-12 job-box">
        <div class="title">招聘岗位</div>
        <div class="job-item" v-for="item in jobs" :key="item">
          <div class="job-name">{{ item.title }}</div>
          <div class="work-add">
            工作地点: {{ item.work_add }}
            <span>学历要求: {{ item.edu }}</span>
            <span>工作经验: {{ item.work_exp }}</span>
            <span>招聘人数: {{ item.numbers }}</span>
            <span>薪资: {{ item.salary }}</span>
          </div>
          <div class="small-name">职位描述</div>
          <!-- <div class="smaller-title">岗位职责：</div> -->
          <div class="content" v-html="item.content"></div>
          <!-- <div class="smaller-title">任职资格：</div>
          <div class="content">
            1、 本科，专业不限，高分子材料等材料、化工类专业优先； 2、
            具有较好的对外协调能力、独立工作能力 3、
            细致、认真，性格外向，思维活跃，并有良好的职业操守。 4、
            应届生专业综合排名中等以上，有社团和班干工作经验，学生党员优先。
          </div> -->
        </div>
        <div class="contact-people">
          <div class="text">联系人：{{ concat.name }}</div>
          <a :href="`tel:${concat.tel}`" class="text"
            >联系方式：{{ concat.tel }}</a
          >
          <div class="text">邮箱：{{ concat.email }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getJob } from '../../../server/join'

const jobs = ref([])
const concat = ref('')

onMounted(async () => {
  const r = await getJob()
  jobs.value = r.list
  concat.value = r.contact
})
</script>
<style lang="stylus" scoped>
.job-box
  & > .title
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom 15px
  .job-item
    border 1px solid #E0E0E0
    padding 20px
    background #fff
    margin-bottom 10px
    color #333333
    .job-name
      font-size 20px
      margin-bottom 10px
    .work-add
      font-size 16px
      margin-bottom 25px
      span
        margin-left 40px
        @media screen and (max-width 768px) {
          margin-left 15px
        }
    .small-name
      font-size 16px
      font-weight bold
      margin-bottom 3px
    .smaller-title
      font-size 16px
      margin-bottom 3px
  .contact-people
    padding 25px 21px
    background #C6ECFF
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    a
      color: #333333;
      text-decoration none
</style>
